<template>
  <div class="text-center">
    <v-btn
      v-if="!pdfType"
      dark
      x-large
      elevation="10"
      color="primary"
      class="rs-report__cta text-h5 text-md-h4 text-lg-h3 py-5 px-10"
      target="_blank"
      @click="handleCta"
    >
      <input
        v-if="isEditing"
        :value="value"
        type="text"
        @input="emitUpdate"
        class="text-center rs-report-section-edit"
        style="font-size: 1.25rem"
      />
      <template v-else>
        <ReportContentOutput :value="value" />
      </template>
    </v-btn>
  </div>
</template>

<script>
import ReportContentOutput from "@/components/ReportContentOutput.vue";

export default {
  components: {
    ReportContentOutput,
  },
  props: {
    isEditing: {
      type: Boolean,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
    advisor: {
      type: Object,
    },
    prospectId: {
      type: String,
    },
    pdfType: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    emitUpdate(e) {
      this.$emit("update:value", e.target.value);
    },

    handleCta() {
      if (this.isEditing) {
        return;
      } else if (this.advisor?.customReportCtaUrl) {
        window.open(this.advisor.customReportCtaUrl, "_self", "rel=noreferrer");
      } else if (this.advisor?.collectContactPostSurvey) {
        this.$router.push("/reduce-risk/" + this.prospectId);
      } else {
        this.$router.push("/contact-requested/" + this.prospectId);
      }
    },
  },
};
</script>
